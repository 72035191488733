.cetagoryWrap{
    background: #f5f5f5;
    margin-bottom: 60px;
    h3{
        background: #ddd;
        padding: 10px 20px 20px;
        position: relative;
        font-size: 24px;
        margin-bottom: 0;
        &::before{
            content: '';
            position: absolute;
            left: 30px;
            top: 100%;
            border: 15px solid transparent;
            border-top-width: 13px;
            border-top-color: rgba(0, 0, 0, .1);
        }
    }
    ul{
        padding: 15px 30px 20px;
        li{
            border-bottom: 1px solid #ddd;
            &:last-child{
                border-bottom: none;
            }
            a{
                font-size: 16px;
                color: #333;
                display: block;
                padding: 15px 0 10px;
                transition: all .3s;
                &:hover{
                    color: #C7983C;
                }
            }
        }
    }
}