.portfolioArea{
    padding: 100px 0px;
    .PortfolioMenu{
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
        li{
            margin: 0px 15px;
            cursor: pointer;
            color: #333;
            transition: all .3s;
            &:hover{
                color: #C7983C;
            }
        }
    }
    .portfolioWrapper,
    .gridPortfolio{
        display: flex;
        flex-wrap: wrap;
        .portfolioItem{
            width: 20%;
            flex: 0 0 20%;
            position: relative;
            overflow: hidden;
            @media (max-width:768px) {
                width: 33.33%;
                flex: 0 0 33.33%;
            }

            @media (max-width:475px) {
                width: 100%;
                flex: 0 0 100%;
            }
            .portfolioContent{
                position: absolute;
                left: 0;
                bottom: -30%;
                visibility: hidden;
                opacity: 0;
                width: 100%;
                height: 100%;
                display: flex;
                padding: 20px;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                background: rgba(21, 26, 48, 0.8);
                transition: all .5s;
                &::before{
                    position: absolute;
                    left: 5%;
                    top: 5%;
                    width: 90%;
                    height: 90%;
                    border: 2px solid #C7983C;
                    content: '',
                }
                p{
                    color: #C7983C;
                }
                h3{
                    color: #fff;
                    position: relative;
                    @media (max-width:1200px) {
                        font-size: 18px;
                        margin-bottom: 0;
                    }
                    &::before{
                        content: "";
                        position: absolute;
                        left: 50%;
                        bottom: -30px;
                        width: 60px;
                        height: 3px;
                        background: #C7983C;
                        transform: translateX(-50%);
                    }
                }
            }
            &:hover{
                .portfolioContent{
                    opacity: 1;
                    visibility: visible;
                    bottom: 0;
                }
            }
        }
    }
    .container{
        .portfolioItem{
            width: 100%;
            flex: 0 0 100%;
            margin-bottom: 30px;
        }
    }
    &.portfolioAreaStyleTwo{
        padding-bottom: 70px;
    }
}